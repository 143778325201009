<template>
  <div class="page-container">

    <router-link to="/">
      <img class="logo" src="@/assets/images/esprezzo-logo.png" />
    </router-link>

    <div ref="containerElement" class="container">

      <LoadingOverlay v-if="form.isLoading" />

      <form @submit="submit" v-if="!form.isSubmitted">
        <h4>Join the Dispatch beta</h4>
        <FormSubmitErrors :formName="formName" />
        <div>
          <FormInput :formName="formName" fieldName="username" ref="focusElement" />
          <FormInput :formName="formName" fieldName="email" />
          <FormInput :formName="formName" fieldName="companyName" />
          <p class="terms">
            By creating an account, you agree to our
            <a href="https://esprezzo.io/terms" class="subtle">Terms of Service</a> and
            <a href="https://esprezzo.io/privacy" class="subtle">Privacy Policy</a>.
          </p>
        </div>
        <button type="submit" :disabled="isAPIReadOnly || form.isLoading || !isFormValid">Continue</button>
        <p class="after-button-note">
          Already have an account? <router-link to="/login">Sign in.</router-link>
        </p>
      </form>

      <template v-else>

        <template v-if="isPendingInvitation">
          <img class="satellite-dish" src="@/assets/images/satellite-dish.png" />
          <h4>Check your email</h4>
          <div class="success-email">
            {{ form.fields.email.value }}
          </div>
          <p class="success-message">
            Thanks for signing up for Esprezzo Dispatch! We've sent you a
            confirmation email. Please click the link in the email to finish
            creating your account.
            <br><br>
            If you haven't received an email from Esprezzo in 15 minutes, please
            check your spam folder.
          </p>
          <button
            type="button"
            @click="resendConfirmationEmail"
            class="resend-confirmation-email-button"
          >
            Re-send email
          </button>
        </template>

        <template v-else>
          <img class="satellite-dish" src="@/assets/images/satellite-dish.png" />
          <h4>You're on the waitlist!</h4>
          <p class="success-message">
            Thank you for signing up for the Dispatch beta.
            <br><br>
            We'll send an email when more beta slots open up, so keep an eye on
            your inbox.
          </p>
        </template>

      </template>

    </div>

    <p class="post-content-footer" v-if="form.isSubmitted && isPendingInvitation">
      Still haven't received your confirmaton email?
      <a target="_blank" href="mailto:support@esprezzo.io?subject=Missing%20Confirmation%20Email&body=I%20have%20not%20yet%20received%20an%20account%20confirmation%20email.">Let us know.</a>
    </p>

    <p class="recaptcha-note">
      Invisible reCAPTCHA by Google
      <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a>.
    </p>

  </div>

</template>

<script>

  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'
  import useRecaptcha from '@/composables/useRecaptcha'

  import FormInput from '@/components/forms/FormInput.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      FormInput,
      LoadingOverlay,
      FormSubmitErrors,
    },
    setup() {

      // data
      const formName = 'signupForm'

      // composables
      const { setRecaptchaToken } = useRecaptcha({ formName, action: 'signup' })
      const { form, isFormValid, focusElement, scrollToFormTop, containerElement } = useForm({ formName })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
        scrollToFormTop,
        containerElement,
        setRecaptchaToken,
      }

    },
    computed: {
      ...mapState('app', ['isAPIReadOnly']),
    },
    data() {
      return {
        newUser: null,
        isPendingInvitation: false,
      }
    },
    created() {

      const { email } = this.$route.query

      this.$router.replace({ query: null })

      if (email) {
        this.$store.commit('forms/SET_FIELD_VALUE', { formName: this.formName, fieldName: 'email', newValue: email })
      }

      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: this.formName, fieldName: 'email', newValue: this.isAPIReadOnly })
      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: this.formName, fieldName: 'username', newValue: this.isAPIReadOnly })

    },
    methods: {
      submit($event) {

        if ($event) $event.preventDefault()

        this.setRecaptchaToken()
          .then(() => {

            return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
              .then((response) => {

                this.newUser = response.data
                this.isPendingInvitation = this.newUser.status === 'pending_invitation'

                this.$mixpanel.onReady((mixpanel) => {

                  const eventData = {
                    user: this.newUser,
                    isPendingInvitation: this.isPendingInvitation,
                  }

                  const userData = {
                    $name: this.newUser.name,
                    $email: this.newUser.email,
                    username: this.newUser.username,
                  }

                  mixpanel.identify(this.newUser.email)
                  mixpanel.people.set_once(userData)
                  mixpanel.track('Signup Form Submitted', eventData)

                  mixpanel['esprezzo.io'].identify(this.newUser.email)
                  mixpanel['esprezzo.io'].people.set_once(userData)
                  mixpanel['esprezzo.io'].track('[Dispatch] Signup Form Submitted', eventData)

                })

              })
              .catch(() => {
                // do nothing
              })
          })
          .finally(() => {
            this.scrollToFormTop()
          })

      },
      resendConfirmationEmail() {

        if (!this.newUser || !this.newUser.email) return

        this.$store.state.api.dispatch
          .post('/accounts/request/resend', { email: this.newUser.email })
          .then(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'We\'ve sent another confirmation email!',
              type: 'success',
            })
          })
          .catch((error) => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not resend confirmation email. Please try again later!',
              type: 'error',
            })
            throw error
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/logged-out-page.styl'

  .satellite-dish
    @apply w-32
    @apply h-32
    @apply mb-4
    @apply mx-auto

  .terms
    @apply mb-5

  .success-email
    @apply text-xl
    @apply break-words
    @apply text-gray-600

  .success-message
    @apply mt-4
    @apply pt-4
    @apply border-t
    @apply border-primary-300

  .resend-confirmation-email-button
    @apply mt-4
    @apply mb-0
    @apply w-auto
    @apply mx-auto

</style>
